import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Hero } from '../components/site-only/hero'
import { Section, SectionHeading } from '../components/section'
import {
  HeadingGroup,
  HeadingSubtitle,
  HeadingTitle,
} from '../components/heading-group'
import {
  useToken,
  Container,
  List,
  ListItem,
  Text,
  GridItem,
  Grid,
} from '@chakra-ui/react'
import { SlideFadeInView } from '../components/slide-fade-in-view'
import { Wrapper } from '../components/site-only/wrapper'
import { ListIcon } from '../components/list-icon'

export default function Disclaimer({
  data: { disclaimer },
}: PageProps<GatsbyTypes.DisclaimerQuery>) {
  const section = disclaimer?.section

  return (
    <>
      {section && (
        <>
          <Hero
            pb={{
              base: '30vw',
              md: 60,
            }}
          >
            <SlideFadeInView>
              <Section textAlign={'center'}>
                <SectionHeading
                  as={HeadingGroup}
                  variant={'white'}
                  textAlign={{
                    base: 'center',
                    md: 'unset',
                  }}
                >
                  <HeadingTitle>{section.heading?.value}</HeadingTitle>
                  <HeadingSubtitle>
                    {section.subtitle?.element?.value}
                  </HeadingSubtitle>
                </SectionHeading>
              </Section>
            </SlideFadeInView>
          </Hero>
          <Wrapper invert>
            <Container>
              <Grid as={'dd'} gap={15}>
                {section.contents.map(content => {
                  if (content.type === 'list') {
                    return (
                      <List
                        key={content.key}
                        spacing={15}
                        variant={'primary'}
                        size={'sm'}
                        ml={'-1em'}
                      >
                        {content.list.map(item => {
                          return (
                            <ListItem key={item.key}>
                              <ListIcon />
                              {item.contents.map(content => {
                                if (content.type === 'list') {
                                  return (
                                    <List key={content.key} size={'xs'}>
                                      {content.list.map(item => {
                                        return (
                                          <ListItem key={item.key}>
                                            <ListIcon />
                                            <Text
                                              dangerouslySetInnerHTML={{
                                                __html: item.value,
                                              }}
                                            />
                                          </ListItem>
                                        )
                                      })}
                                    </List>
                                  )
                                } else {
                                  return (
                                    <GridItem
                                      key={content.key}
                                      dangerouslySetInnerHTML={{
                                        __html: content.value,
                                      }}
                                    />
                                  )
                                }
                              })}
                            </ListItem>
                          )
                        })}
                      </List>
                    )
                  } else {
                    return (
                      <GridItem
                        key={content.key}
                        dangerouslySetInnerHTML={{
                          __html: content.value,
                        }}
                      />
                    )
                  }
                })}
              </Grid>
            </Container>
          </Wrapper>
        </>
      )}
    </>
  )
}

export const query = graphql`
  query Disclaimer {
    disclaimer: markdownRemark(frontmatter: { title: { eq: "Disclaimer" } }) {
      section: childElement(filter: { type: { eq: "section" } }) {
        tagName
        ...MarkdownHeading
        ...MarkdownSubtitle
        contents: childrenElement(
          filter: {
            type: { in: ["paragraph", "list"] }
            children: { type: { nin: ["strong"] } }
          }
        ) {
          key: id
          type
          tagName
          value
          list: childrenElement(filter: { type: { eq: "listItem" } }) {
            key: id
            tagName
            value
            contents: childrenElement(
              filter: {
                type: { in: ["paragraph", "list"] }
                children: { type: { nin: ["strong"] } }
              }
            ) {
              key: id
              type
              tagName
              value
              list: childrenElement(filter: { type: { eq: "listItem" } }) {
                key: id
                tagName
                value
              }
            }
          }
        }
      }
    }
  }
`
